<template>
  <div>
    <!-- 有机会运单 -->
    <nav-bar :nav-info='navInfo'></nav-bar>
    <!-- 搜索框 和tabbar-->
    <div class="searchBox">
      <div class="tab_search">
        <form action="javascript:return ture">
          <van-search v-model="searchValue" shape="round" @search="onSearch"   placeholder="请输入搜索关键词"/>
        </form>
      </div>
    </div>
    <!-- //计划显示 -->
    <div >
      <car-list ref="carList"></car-list>
    </div>
  </div>
</template>
<script>
import NavBar from '../../components/NavBar/NavBar.vue'
import CarList from '../../components/Order/CarList.vue'
export default {
  components: {
    NavBar,
    CarList
  },
  data() {
    return {
      navInfo: {
        title:'运力监控',
        left:true,
        path:'/planWaybill'
      },
      searchValue:'',
    }
  },
  created() {
    // console.log('plan',this.$route.query);
    this.onSearch(this.$route.query.transportNo);
  },
  methods: {
    //搜索框事件
    onSearch(value) {
      this.$nextTick(()=> {
        this.$refs.carList.getList(0,value)
      })
    }, 
  },
  //路由导航守卫
   beforeRouteLeave(to,from,next) {
    if (to.name=='PlanWaybill') {
        to.meta.KeepAlive = true
        next();
    } else {
        next();
    }
  }
}
</script>
<style lang="scss"  scoped>

// 搜索框和tabbar
.searchBox {
  height: 1.55rem;
  background: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
  .tab_left {
    font-size: 0.37rem;
    // width: 1.5rem;
    text-align: center;
    padding: 0 0.1rem;
    margin-left: 0.5rem;
    color: #999999;
  }
  .tab_rigth {
    font-size: 0.37rem;
    // width: 1.5rem;
    text-align: center;
    padding: 0 0.1rem;
    margin-left: 0.5rem;
    color: #999999;
  }
  .active {
    color: #000000;
    font-size: 0.43rem;
    border-bottom: 0.1rem solid #FB6600;
  }
  .tab_search {
    flex: 1;
  }
  .van-search {
    background: #F5F5F5;
  }
  .van-cell {
    background: #FFFFFF;
  
  }
}
// 搜索框和tabbar结束
.fenge {
   height: 0.03rem;
   margin-top: 0.27rem;
   background: #F5F5F5;        
}







// screen弹出框
.screen_title {
  font-size: 0.43rem;
  text-align: center;
  line-height: 0.59rem;
  padding-top: 0.27rem;
}
h4 {
  font-size: 0.37rem;
  font-weight: 400;
  line-height: 0.53rem;
  padding: 0.27rem 0 0.17rem 0.27rem;
}
h3 {
  font-size: 0.37rem;
}
.screen_type {
  height: 0.8rem;
  font-size: 0.32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .screen_type_item {
    margin: 0.3rem;
    width: 2.35rem;
    height: 100%;
    background: #F5F5F5;
    text-align: center;
    line-height: 0.8rem;
    border-radius: 0.8rem;
    color: #999999;
  }
  .screen_type_check {
    color: #FB6600;
    background: rgba($color: #FB6600, $alpha: 0.1);
  }
}
.screen_orderNo,.screen_ys_orderNo,.screen_ys_orderNo {
  width: 9.15rem;
  height: 0.8rem;
  border: #FB6600;
  margin: 0 auto;
}
.van-cell {
  background: #F5F5F5;
  border-radius: 0.8rem;
  padding: 0.15rem 0.4rem;
}
.screen_sub {
  height: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.45rem;
  div {
    height: 100%;
    width: 2.13rem;
    border-radius: 0.85rem;
    text-align: center;
    color: #FFFFFF;
    font-size: 0.43rem;
    line-height: 0.85rem;
    background: #FB6600;
  }
}
.screen_time {
  width: 9.15rem;
  height: 0.8rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    width: 4.21rem;
    /deep/.el-input__inner {
      border-radius: 0.8rem;
      height: 0.8rem;
       background: #F5F5F5;
       border: 0;
    }
    /deep/.el-input__icon {
      opacity: 0;
    }
  }
}
//screen弹出框结束

/deep/.van-popup {
  max-height: 85%;
}
</style>